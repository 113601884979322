// pages
.container {
    position: relative;
}

.page {
    position: absolute;
    left: 0;
    right: 0;
    min-height: calc(100vh - 153px);
    display: flex;
    flex-direction: column;
}

.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: translateX(0);
}

.page-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

// global flex
html {
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
}

// custom CSS
.nav-link--active {
    background-color: #fce8e6;
    font-weight: 700;
    color: #d93025 !important;
    cursor: default;

    &:active,
    &:hover,
    &:focus {
        background-color: #fce8e6 !important;
        font-weight: 700;
        color: #d93025 !important;
    }
}

.region-icon {
    width: 22px;
}